import {ITkProductModel} from '../../models/product'
import {ITkCart, ITkProductCartAvailability} from '../../models/cart'
import {useCallback, useEffect, useState} from 'react'
import {httpPostGraphQL} from '../../utils/http-utils'
import {
  applyCouponMutation,
  copyListToCartMutation,
  currentCartQuery,
  destroyCartMutation,
  findCouponByRoleQuery,
  getCartExcelQuery,
  getCartPdfQuery,
  removeCouponMutation,
  removeFromCartMutation,
  updateCartMutation,
  validateProductsAvailabilityQuery,
} from './queries'
import {addOrRemoveToCart} from '../../utils/analytics-utils'
import {GraphQLResult, transformGraphQLErrors} from '../../models/graphql'
import {DownloadFile, FileTypes} from '../../models/file'
import {ShipmentCost} from '../../models/shipment'
import {TkEngagementFunnelEntryType} from "../../models/engagementFunnel";
import {registerEngagementMutation} from "../TkRecommenderContext/queries";

export interface TkCartContextType {
  addToCart(
    product: ITkProductModel,
    quantity: number,
    isWithdraw: boolean,
  ): Promise<ITkCart | null | undefined>

  removeFromCart(product: ITkProductModel): Promise<ITkCart | null | undefined>

  updateCart(
    product: ITkProductModel,
    quantity: number,
    isWithdraw: boolean,
  ): Promise<ITkCart | null | undefined>

  copyListToCart(
    listId: string
  ): Promise<GraphQLResult<ITkCart> | null | undefined>

  updatingCart: boolean

  isUpdatingCartItem(product: ITkProductModel): boolean

  destroyCart(): Promise<GraphQLResult<ITkCart> | null | undefined>

  getCart(): Promise<ITkCart | null | undefined>

  currentCart?: ITkCart | null

  percentCartWithdraw: () => number

  clearCart(): void

  getCartAsFile(fileType: FileTypes): Promise<GraphQLResult<DownloadFile>>

  calculateSubTotal(shipmentCost?: ShipmentCost): number | null | undefined

  validateProductsAvailability(): Promise<GraphQLResult<ITkProductCartAvailability> | null | undefined>

  applyCoupon(coupon: string): Promise<ITkCart | null | undefined>

  removeCoupon(): Promise<ITkCart | null | undefined>

  findCouponByRole(): Promise<string | null | undefined>
}

const destructToCart = (obj: any) => {
  const {_id, updatedAt, createdAt, totalValue, totalQuantity, items, coupon, couponSavings, totalValueWithSavings} = obj
  return {
    _id,
    updatedAt,
    createdAt,
    totalQuantity,
    totalValue,
    items,
    coupon,
    couponSavings,
    totalValueWithSavings
  }
}

const TkCartContext = (): TkCartContextType => {
  const [currentCart, setCurrentCart] = useState<ITkCart | null | undefined>()
  const [updatingCart, setUpdatingCart] = useState<{
    [key: string]: boolean
  }>({})



  const changeCart = useCallback(async (product: ITkProductModel, quantity: number, addOrUpdate: boolean, isWithdraw: boolean): Promise<ITkCart | null | undefined> => {
      try {
        setUpdatingCart(current => ({
          ...current,
          [product._id]: true,
        }))
        const {data: result} = await httpPostGraphQL({
          query: updateCartMutation,
          variables: {productId: product._id, quantity, addOrUpdate, isWithdraw, isCameFromRecommender: product.isCameFromRecommender},
        })
        const {data} = result

        if (result.errors) {
          return Promise.reject(result.errors)
        } else if (data.updateCart) {
          const cart = destructToCart(data.updateCart)
          setCurrentCart(cart)

          addOrRemoveToCart(cart, addOrUpdate)

          if (product.isCameFromRecommender) {
            await httpPostGraphQL({
              query: registerEngagementMutation,
              variables: {type: TkEngagementFunnelEntryType.car, productIds: [product._id], screen: document.location.href}
            });
          }

          return Promise.resolve(cart)
        } else {
          return Promise.resolve({})
        }
      } catch (e) {
        return Promise.reject(e)
      } finally {
        setUpdatingCart(current => {
          delete current[product._id]
          return {...current}
        })
      }
    },
    []
  )

  const addToCart = async (product: ITkProductModel, quantity: number, isWithdraw: boolean) =>
    await changeCart(product, quantity, true, isWithdraw)
  const updateCart = async (product: ITkProductModel, quantity: number, isWithdraw: boolean) =>
    await changeCart(product, quantity, false, isWithdraw)

  const removeFromCart = useCallback(async (product: ITkProductModel): Promise<ITkCart | null | undefined> => {
      try {
        setUpdatingCart(current => {
          delete current[product._id]
          return {...current}
        })
        const {data: result} = await httpPostGraphQL({
          query: removeFromCartMutation,
          variables: {productId: product._id},
        })

        const {data} = result

        if (result.errors) {
          return Promise.reject(result.errors)
        } else if (data.removeFromCart) {
          const cart = destructToCart(data.removeFromCart)
          setCurrentCart(cart)

          addOrRemoveToCart(cart, false)

          return Promise.resolve(cart)
        } else {
          return Promise.resolve({})
        }
      } catch (e) {
        return Promise.reject(e)
      } finally {
        setUpdatingCart(current => {
          delete current[product._id]
          return {...current}
        })
      }
    },
    []
  )

  const destroyCart = useCallback(async (): Promise<GraphQLResult<ITkCart> | null | undefined> => {
    try {
      const {data: result} = await httpPostGraphQL({
        query: destroyCartMutation,
      })

      const {data} = result

      if (result.errors) {
        return Promise.reject(result.errors)
      } else if (data.destroyCart) {
        const cart = destructToCart(data.destroyCart)
        setCurrentCart(null)
        return Promise.resolve({
          data: cart,
        })
      } else {
        return Promise.resolve({})
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }, [])

  const getCart = useCallback(async (): Promise<ITkCart> => {
    try {
      const {data: result} = await httpPostGraphQL({
        query: currentCartQuery,
      })
      const {data} = result

      if (result.errors) {
        return Promise.reject(result.errors)
      } else if (data.getCurrentCart) {
        const cart = destructToCart(data.getCurrentCart)
        setCurrentCart(cart)
        return Promise.resolve(cart)
      } else {
        return Promise.resolve({})
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }, [])

  const copyListToCart = useCallback(async (listId: string): Promise<GraphQLResult<ITkCart>> => {
      try {
        const {
          data: {
            data: {copyListToCart},
            errors,
          },
        } = await httpPostGraphQL({
          query: copyListToCartMutation,
          variables: {listId},
        })

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          })
        }

        const cart = destructToCart(copyListToCart)
        setCurrentCart(cart)

        return {
          data: cart,
          success: true,
        }
      } catch (e) {
        throw e
      }
    },
    []
  )

  const getCartAsFile = useCallback(async (fileType: FileTypes): Promise<GraphQLResult<DownloadFile>> => {
      try {
        let query, resultDataName: string

        switch (fileType) {
          case FileTypes.PDF:
            query = getCartPdfQuery
            resultDataName = 'getCartPdf'
            break
          case FileTypes.XLSX:
            query = getCartExcelQuery
            resultDataName = 'getCartExcel'
            break
          default:
            query = null
            resultDataName = ''
        }

        const {
          data: {
            data: {[resultDataName]: result},
            errors,
          },
        } = await httpPostGraphQL({
          query,
        })

        if (errors) {
          return Promise.reject({
            success: false,
            errors: transformGraphQLErrors(errors),
          })
        }

        return {
          data: result,
          success: true,
        }
      } catch (e) {
        throw e
      }
    },
    []
  )

  const clearCart = useCallback(() => {
    setCurrentCart({})
  }, [])

  const calculateSubTotal = useCallback((shipmentCost?: ShipmentCost) => {
      let totalValue = currentCart?.totalValue || 0

      if (currentCart?.items) {
        totalValue += ((shipmentCost?.shipmentCost || 0) + (shipmentCost?.backorderShipment?.value || 0))
      }

      return totalValue
    },
    [currentCart]
  )

  const validateProductsAvailability = useCallback(async (): Promise<GraphQLResult<ITkProductCartAvailability> | null | undefined> => {
    try {
      const {
        data: {
          data: {validateProductsAvailability},
          errors,
        },
      } = await httpPostGraphQL({
        query: validateProductsAvailabilityQuery,
      })

      if (errors) {
        return Promise.reject({
          success: false,
          errors: transformGraphQLErrors(errors),
        })
      }

      return Promise.resolve({
        success: true,
        data: validateProductsAvailability,
      })
    } catch (e) {
      console.error('Fail to validate availability cart products', e)
      return Promise.reject({
        success: false,
      })
    }
  }, [])

  const percentCartWithdraw = useCallback((): number => {
    if (!currentCart?.items) return 0

    const countWithdraw = currentCart?.items?.filter(i => i.isWithdraw).length

    return countWithdraw * 100 / currentCart?.items?.length
  }, [currentCart])

  useEffect(() => {
    (async () => await getCart())()
  }, [getCart])

  const applyCoupon = async (couponName: string) => {
    try {
        const {data: result} = await httpPostGraphQL({
            query: applyCouponMutation,
            variables: {couponName},
        })

        if (result.errors) {
            return Promise.reject(result.errors)
        } else if (result.data.applyCoupon) {
            const cart = destructToCart(result.data.applyCoupon)
            setCurrentCart(cart)
            return Promise.resolve(cart)
        } else {
            return Promise.resolve({})
        }
        } catch (e) {
        return Promise.reject(e)
    }
  }

  const removeCoupon = async () => {
    try {
      const {data: result} = await httpPostGraphQL({
        query: removeCouponMutation,
      })

      if (result.errors) {
        return Promise.reject(result.errors)
      }

      if (result.data.removeCoupon) {
        const cart = destructToCart(result.data.removeCoupon)
        setCurrentCart(cart)
        return Promise.resolve(cart)
      }
    } catch (e) {
      return Promise.reject(e)
    }
  }

  const findCouponByRole = async () => {
    try {
      const {data: result} = await httpPostGraphQL({
        query: findCouponByRoleQuery,
      })

      if (result.errors) return Promise.reject(result.errors)
      if (result.data.findCouponByRole) return Promise.resolve(result.data.findCouponByRole)

      return Promise.resolve({})
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    getCart,
    addToCart,
    currentCart,
    percentCartWithdraw,
    updateCart,
    updatingCart: Object.keys(updatingCart).length > 0,
    isUpdatingCartItem: (product: ITkProductModel) => updatingCart[product._id],    
    copyListToCart,
    removeFromCart,
    destroyCart,
    clearCart,
    getCartAsFile,
    calculateSubTotal,
    validateProductsAvailability,
    applyCoupon,
    removeCoupon,
    findCouponByRole,
  }
}

export default TkCartContext
