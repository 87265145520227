import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import DeviceStorage, {destroyAuthToken, getAuthToken, getFingerPrint} from "./storage-utils";
import {isDevEnv} from "./utils";

const DEFAULT_CONFIG: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_GRAPHQL,
    timeout: isDevEnv() ? 60000 : 30000,
    withCredentials: true
};

const createAxios = (config: AxiosRequestConfig = {}): AxiosInstance => {
    const headers = {headers: config.headers || {}};
    const auth_token = getAuthToken();

    if (config) delete config.headers;

    headers.headers.FP = getFingerPrint();
    headers.headers.AppVersion = process.env.REACT_APP_VERSION;

    if (auth_token) headers.headers.Authorization = `Bearer ${auth_token}`;

    const instance = axios.create({...DEFAULT_CONFIG, ...headers, ...config});

    instance.interceptors.response.use(response => response, error => {

        if (error && error.response && error.response.status === 401) {
            destroyAuthToken();
            DeviceStorage.destroyUser();
        }

        return Promise.reject(error);
    });

    return instance;
};

export const getS3Object = (path: string) => {
    return `${process.env.REACT_APP_S3_BUCKET}${path}`
}

export const httpGet = (path: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return createAxios(conf).get(path);
};

export const httpPut = (path: string, payload: any, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return createAxios(conf).put(path, payload);
};

export const httpPost = (path: string, payload: any, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return createAxios(conf).post(path, payload);
};

export const httpPostGraphQL = (payload: any, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    const headers = conf.headers || {};
    headers['Content-Type'] = 'application/json';
    conf.headers = headers;

    return createAxios(conf).post(DEFAULT_CONFIG.baseURL || '', payload);
};

export const httpDelete = (path: string, conf: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return createAxios(conf).delete(path);
};

export const base64ToBlob = async (base64: string, type: string) => {
    const sliceSize = 1024;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);

        let bytes = new Array(end - begin);
        for (let offset = begin, i = 0 ; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type });
}

export const downloadBase64File = async (base64: string, mimeType: string, fileName: string, extension: string) => {
    const d = URL.createObjectURL(await base64ToBlob(base64, mimeType));
    const link = document.createElement('a');
    link.href = d;
    link.download= `${fileName}.${extension}`;
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
};

export const downloadPdfFile = async (base64: string, fileName: string, extension: string) => {
    return downloadBase64File(base64, 'application/pdf', fileName, extension)
}

export const downloadExcelFile = async (base64: string, fileName: string, extension: string) => {
    return downloadBase64File(base64, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', fileName, extension)
}
